<template>
  <div id="reportes" class="reportes">
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
    <div v-if="!loading" class="layout row media-layout">
      <v-container grid-list-xl fluid>
        <v-layout align-center justify-center>
          <v-flex xs12>
            <v-card>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 sm3 lg3>
                    <v-menu
                      ref="finiMenu"
                      v-model="finiMenu"
                      class="mr-2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      :return-value.sync="fini"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="iniFormatted"
                          :label="$t('common.fecha_inicio') + ' *'"
                          append-icon="mdi-calendar"
                          readonly
                          class="input_fnacimiento"
                          v-on="on"
                          @blur="fini = parseDate(iniFormatted)"
                        />
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="fini"
                        first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        min="2017-01-01"
                        :max="fmax.toISOString().substr(0, 10)"
                        no-title
                        scrollable
                        @input="$refs.finiMenu.save(fini)"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm3 lg3>
                    <v-menu
                      ref="ffinMenu"
                      v-model="ffinMenu"
                      class="mr-2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      :return-value.sync="ffin"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="finFormatted"
                          :label="$t('common.fecha_fin') + ' *'"
                          append-icon="mdi-calendar"
                          readonly
                          class="input_fnacimiento"
                          v-on="on"
                          @blur="ffin = parseDate(finFormatted)"
                        />
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="ffin"
                        first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        min="2017-01-01"
                        :max="fmax.toISOString().substr(0, 10)"
                        no-title
                        scrollable
                        @input="$refs.ffinMenu.save(ffin)"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm3 lg3>
                    <v-select
                      v-model="estado"
                      style="width: 98%;"
                      :items="tipoEstado"
                      item-text="name"
                      item-value="id"
                    />
                  </v-flex>
                  <v-flex xs12 sm4 lg4 class="text-sm-right">
                    <v-btn
                      :disabled="disabledButton"
                      :color="custom_template ? 'primary' : 'info'"
                      @click="filtrarResenas()"
                    >
                      <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm12>
            <v-card class="mb-4">
              <v-toolbar color="primary" dark flat dense cad>
                <v-toolbar-title class="subheading">{{ $t('menu.resenas') }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-card-text class="">
                <v-data-table
                  :headers="headers"
                  :items="resenas"
                  :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
                  class="elevation-1"
                  :items-per-page-text="$t('datatable.rows_per_page')"
                  item-key="name"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon :color="props.item.estado_ico" v-on="on">mdi-earth</v-icon></template
                          >
                          <span>{{ props.item.estado_texto }}</span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">{{ props.item.fecha }}</td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              <v-icon class="valoracion_star" :color="props.item.puntuacion >= 1 ? 'amber' : 'grey'"
                                >star</v-icon
                              >
                              <v-icon class="valoracion_star" :color="props.item.puntuacion >= 2 ? 'amber' : 'grey'"
                                >star</v-icon
                              >
                              <v-icon class="valoracion_star" :color="props.item.puntuacion >= 3 ? 'amber' : 'grey'"
                                >star</v-icon
                              >
                              <v-icon class="valoracion_star" :color="props.item.puntuacion >= 4 ? 'amber' : 'grey'"
                                >star</v-icon
                              >
                              <v-icon class="valoracion_star" :color="props.item.puntuacion >= 5 ? 'amber' : 'grey'"
                                >star</v-icon
                              >
                            </span>
                          </template>
                          <span>{{ props.item.comentarios }}</span>
                        </v-tooltip>
                      </td>
                      <td>{{ props.item.nombreDoctor }}</td>
                      <td class="text-center">
                        <v-tooltip v-if="props.item.estado === 0" bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              depressed
                              outlined
                              icon
                              fab
                              dark
                              color="primary"
                              small
                              v-on="on"
                              @click="mostrarModal('publish', props.item.id)"
                            >
                              <v-icon>mdi-cloud_upload</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('landing.publicar') }}</span>
                        </v-tooltip>
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              depressed
                              outlined
                              icon
                              fab
                              dark
                              color="primary"
                              small
                              v-on="on"
                              @click="mostrarModal('unpublish', props.item.id)"
                            >
                              <v-icon>mdi-cloud-off-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('landing.despublicar') }}</span>
                        </v-tooltip>
                        <v-btn
                          depressed
                          outlined
                          icon
                          fab
                          dark
                          color="pink"
                          small
                          @click="mostrarModal('delete', props.item.id)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>

                  <template slot="pageText" slot-scope="props">
                    {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }} {{ props.itemsLength }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog v-model="dialog.dialog" dialog_delete max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialog.titulo }}</span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <p>{{ dialog.texto }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey darken-1" text @click.native="dialog.dialog = false"
              ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
            >
            <v-btn
              :color="dialog.color_btn_confirm + ' darken-1'"
              text
              @click.native="
                dialog.dialog = false;
                confirmarDialog();
              "
              ><v-icon>{{ dialog.accion }}</v-icon> {{ $t('common.confirm') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="alerta.color"
        top="top"
        :multi-line="mode === 'multi-line'"
        :vertical="mode === 'vertical'"
      >
        <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
        >&nbsp;{{ alerta.texto }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { resenasUrl, resenasEditUrl, getHeader } from '../config/config';

export default {
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    fini: null,
    finiMenu: false,
    ffinMenu: false,
    ffin: null,
    iniFormatted: null,
    finFormatted: null,
    estado: 2,
    tipoEstado: [
      { id: 2, name: 'Todos' },
      { id: 1, name: 'Publicados' },
      { id: 0, name: 'Despublicados' },
    ],
    idiomas: [
      { id: 'es', name: 'Español' },
      { id: 'en', name: 'Ingles' },
      { id: 'ar', name: 'Arabe' },
      { id: 'pt', name: 'Portugues' },
      { id: 'pl', name: 'Polaco' },
    ],
    fmax: null,
    es_iconnecta: false,
    snackbar: false,
    disabled: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },
    es_medyola:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] === 'Medyola'
        ? true
        : false,
    headers: [
      { text: 'Estado', align: 'center', value: 'estado' },
      { text: 'Fecha', align: 'center', value: 'fecha' },
      { text: 'Puntuación', align: 'left', value: 'puntuacion', sortable: false },
      { text: 'Doctor', align: 'left', value: 'nombreProfesional' },
      { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },
    ],
    resenas: [],
    dialog: {
      titulo: '',
      texto: '',
      accion: '',
      id: 0,
      color_btn_confirm: '',
      dialog: false,
    },
    disabledButton: true,
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
  }),

  watch: {
    fini() {
      this.iniFormatted = this.formatDate(this.fini);
    },
    ffin() {
      this.finFormatted = this.formatDate(this.ffin);
    },
  },
  created() {
    const date = new Date();
    const primerDia = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fmax = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fini = primerDia.getFullYear() + '-' + (primerDia.getMonth() + 1) + '-' + primerDia.getDate();
    this.ffin = ultimoDia.getFullYear() + '-' + (ultimoDia.getMonth() + 1) + '-' + ultimoDia.getDate();
    this.$http
      .post(
        resenasUrl,
        { id: this.authUser.id, fini: this.fini, ffin: this.ffin, estado: this.estado },
        { headers: getHeader() }
      )
      .then(response => {
        if (response.status === 200 && response.data) {
          this.loading = false;
          this.resenas = response.data.resenas;
        }
        this.disabledButton = false;
      });
  },

  methods: {
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    confirmarDialog() {
      this.$http
        .post(
          resenasEditUrl,
          { user_id: this.authUser.id, resena_id: this.dialog.id, accion: this.dialog.accion },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.alerta.texto = this.$t('success.save_common');
            this.alerta.icono = 'mdi-check';
            this.alerta.color = 'success';
            this.resenas = response.data.resenas;
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.icono = 'mdi-alert-circle';
            this.alerta.color = 'warning';
          }
          this.snackbar = true;
          this.loading = false;
        });
    },
    mostrarModal(accion, id) {
      if (accion === 'publish') {
        this.dialog.titulo = this.$t('resenas.titulo_publicar');
        this.dialog.texto = this.$t('resenas.texto_publicar');
        this.dialog.color_btn_confirm = 'blue';
      } else if (accion === 'unpublish') {
        this.dialog.titulo = this.$t('resenas.titulo_despublicar');
        this.dialog.texto = this.$t('resenas.texto_despublicar');
        this.dialog.color_btn_confirm = 'blue';
      } else {
        this.dialog.titulo = this.$t('resenas.titulo_eliminar');
        this.dialog.texto = this.$t('resenas.texto_eliminar');
        this.dialog.color_btn_confirm = 'red';
      }
      this.dialog.accion = accion;
      this.dialog.id = id;
      this.dialog.dialog = true;
    },
    filtrarResenas() {
      if (!this.disabledButton) {
        this.disabledButton = true;
        this.$http
          .post(
            resenasUrl,
            { id: this.authUser.id, fini: this.fini, ffin: this.ffin, estado: this.estado },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data) {
              this.loading = false;
              this.resenas = response.data.resenas;
            }
            this.disabledButton = false;
          });
      }
    },
  },
};
</script>
<style scoped lang="css">
.valoracion_star {
  font-size: 16px;
  margin-right: -4px;
}
</style>
