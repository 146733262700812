import { render, staticRenderFns } from "./Resenas.vue?vue&type=template&id=1fce4cc5&scoped=true"
import script from "./Resenas.vue?vue&type=script&lang=js"
export * from "./Resenas.vue?vue&type=script&lang=js"
import style0 from "./Resenas.vue?vue&type=style&index=0&id=1fce4cc5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fce4cc5",
  null
  
)

export default component.exports